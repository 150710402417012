import { gql } from "apollo-boost";
import { idtype, linkTo } from "querypieces";

export default gql`
{
  nav: globalSet(handle:"nav") {
    ...on nav_GlobalSet {
      phoneNumber
      builder: navBuilder {
        ...on navBuilder_item_BlockType {
          ${idtype}
          ${linkTo}
        }
      }
    }
  }
}
`;
