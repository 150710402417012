import { useEffect, useState } from "react";

const useMinWidth = (width) => {
  const [matches, setMatches] = useState();
  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${width}px)`);
    setMatches(media.matches);
    const onChange = (e) => {
      setMatches(e.matches);
    };
    if (typeof media.addEventListener !== "undefined") {
      media.addEventListener("change", onChange);
      return () => media.removeEventListener("change", onChange);
    } else {
      //ios doesnt have their shit together yet
      media.addListener("change", onChange);
      return () => media.removeListener("change", onChange);
    }
  }, [width]);
  return matches;
};

export default useMinWidth;
