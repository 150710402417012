import React, { useEffect, useState } from "react";
import styles from "./form.module.scss";
import HTMLContent from "dangerously-set-html-content";
import RichText from "components/rich-text";
import "./form.scss";

const useForm = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    fetch(`/form`)
      .then((res) => res.text())
      .then((res) => {
        setLoading(false);
        setData(res);
      });
  }, []);

  return { loading, data };
};
const Form = ({ form, heading, richText }) => {
  const { loading, data: formData } = useForm();

  return (
    <div className={styles.element}>
      <h2 className={styles.heading}>{heading}</h2>
      <div className={styles.inner}>
        <RichText className={styles.text} text={richText} />
        {loading || !formData ? (
          "Loading"
        ) : (
          <div className={styles.form}>
            <HTMLContent
              html={formData.replace("<form", `<form action="/form"`)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
