import React from "react";
import Product from "blocks/products/product";
import styles from "./products.module.scss";

const Products = ({ products, heading, cardSize }) => {
  return (
    <section className={styles.element}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      <div className={`${styles.cards} ${cardSize}`}>
        {products.map(({ id, ...props }, i) => (
          <Product
            key={id}
            {...props}
            size={cardSize}
            align={
              cardSize === "large" ? (i % 2 === 0 ? "left" : "right") : null
            }
          />
        ))}
      </div>
    </section>
  );
};

export default Products;
