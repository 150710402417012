import { idtype } from "querypieces";

export default `
...on pageBuilder_blogPosts_BlockType {
	${idtype}
	heading
	initialPosts
	blogPostsButtonType
}
`;
