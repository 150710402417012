import { idtype } from "querypieces";

export default `
...on pageBuilder_features_BlockType {
	${idtype}
	productFeatures {
		...on productFeatures_item_BlockType {
			${idtype}
			heading
			text
		}
	}
}
`;
