import React from "react";
import styles from "./image-heading-link.module.scss";
import Link from "components/link";
import Image from "components/image";

const ImageHeadingLink = ({ heading, linkTo, image }) => {
  return (
    <div className={styles.element}>
      <div className={styles.heading}>{heading}</div>
      {/* todo use h */}
      <Image className={styles.image} {...image[0].optimized} />
      <Link {...linkTo} className={styles.link} />
    </div>
  );
};

export default ImageHeadingLink;
