import { gql } from "apollo-boost";
import { linkTo, link } from "querypieces";

export default gql`
{
  globalSet (handle: "footer") {
    ...on footer_GlobalSet {
      footer {
        ...on footer_BlockType {
          contactHeading
          mailchimpFormActionLink
          contactLink {
            ${link}
          }
          addressHeading
          address
          additionalLinksHeading
          additionalLinks {
            ...on additionalLinks_link_BlockType {
              id
              ${linkTo}
            }
          }
          socialLinksHeading
          socialLinks {
            ...on socialLinks_link_BlockType {
              id
              ${linkTo}
            }
          }
          copyrightNotice
          termsLink {
            ${link}
          }
        }
      }
    }
  }
}`;
