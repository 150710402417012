export const idtype = `id
typeHandle`;

export const link = `
  url
  text
  type
  element {
    id
    uri
    title
  }
`;

export const linkTo = `
linkTo {
  ${link}
}
`;

export const optimized = `
src
srcset
srcsetWebp
placeholderImage
width: originalImageWidth
height: originalImageHeight
`;

export const image = `
  image {
    ...on images_Asset {
     optimized: optimizedAny {
       ${optimized}
     }
    }
  }
`;

const optimized32 = `
optimized: optimized32 {
  ${optimized}
}`;

const optimized11 = `
optimized: optimized11 {
  ${optimized}
}`;

const optimized41 = `
optimized: optimized41 {
  ${optimized}
}
`;

export const image32 = `
image: image32 {
  ...on images_Asset {
    ${optimized32}
  }
}
`;

export const image11 = `
  image: image11 {
    ...on images_Asset {
      ${optimized11}
    }
  }
`;

export const personImage = `
  image: photo {
    ...on images_Asset {
      ${optimized11}
    }
  }
`;

export const image41 = `
  image: image41 {
    ...on images_Asset {
      ${optimized41}
    }
  }
`;

export const productImage = `
image: productImage {
  ...on images_Asset {
    optimized32 { ${optimized} }
    optimized11 { ${optimized} }
  }
}
`;

export const blogCard = `
... on blog_blog_Entry {
  id
  title
  uri
  postDate @formatDateTime(format: "F j, Y")
  cardColor
  ${image32}
}
`;
