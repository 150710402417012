import imageHeadingLink from "./image-heading-link/query";
import statement from "./statement/query";
import products from "./products/query";
import wideImage from "./wide-image/query";
import sect from "./sect/query";
import text from "./text/query";
import embed from "./embed/query";
import features from "./features/query";
import quote from "./quote/query";
import services from "./services/query";
import people from "./people/query";
import faq from "./faq/query";
import image from "./image/query";
import blogPosts from "./blog-posts/query";
import form from "./form/query";
import headingOnly from "./heading-only/query";
import faqSubnav from "./faq-subnav/query";
import code from "./code/query";
import bigQuote from "./big-quote/query";
/* import-cursor */

const blocks = [
  imageHeadingLink,
  statement,
  products,
  wideImage,
  sect,
  text,
  embed,
  features,
  quote,
  services,
  people,
  faq,
  blogPosts,
  form,
  headingOnly,
  faqSubnav,
  code,
  bigQuote,
  /* object-cursor */
].join("\n");

export default blocks;

export const pageBuilder = `
pageBuilder {
  ${blocks}
}
`;

const blogBlocks = [text, image, embed, quote, code]
  .map((query) => query.replace(/pageBuilder/g, "blogBuilder"))
  .join("\n");

export const blogBuilder = `
blogBuilder {
  ${blogBlocks}
}
`;
