import { idtype } from "querypieces";

export default `
...on pageBuilder_bigQuote_BlockType {
	${idtype}
	plainText
	attribution
	theme
}
`;
