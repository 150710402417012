import React from "react";
import Blocks from "blocks";
import Page from "components/page";
import ProductDetail from "components/product-detail";
import query from "./query";

const ProductPage = ({ match }) => {
  return (
    <Page match={match} query={query}>
      {(data) => {
        return (
          <>
            <ProductDetail {...data.entry} />
            <Blocks blocks={data.entry.pageBuilder} />
          </>
        );
      }}
    </Page>
  );
};

export default ProductPage;
