import { idtype, productImage, linkTo } from "querypieces";

export default `
...on pageBuilder_products_BlockType {
	${idtype}
	heading
	cardSize
	products {
		...on products_product_Entry {
			${idtype}
			title
			productCollection
			productId
			${productImage}
			shortDescription
			uri
			hasPage
			richText
			${linkTo}
		}
	}
}
`;
