import { idtype, personImage } from "querypieces";

export default `
...on pageBuilder_people_BlockType {
	${idtype}
	heading
	people {
		...on people_person_BlockType {
			${idtype}
			fullName
			jobTitle
			bio
			${personImage}
		}
	}
}
`;
