import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { blogCard } from "querypieces";
const query = gql`
  query($limit: Int, $offset: Int) {
    entries(section: "blog", offset: $offset, limit: $limit) {
      ${blogCard}
    }
    total: entryCount(section: "blog")
  }
`;
export default ({ initialPosts = 3 }) => {
  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: {
      offset: 0,
      limit: initialPosts,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadMore = () => {
    fetchMore({
      variables: {
        offset: data.entries.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  };

  return {
    loading,
    error,
    data,
    loadMore,
  };
};
