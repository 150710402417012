import { idtype, optimized } from "querypieces";

export default `
...on pageBuilder_wideImage_BlockType {
	${idtype}
	image: image41 {
		...on images_Asset {
			optimized41 {
				${optimized}
			}
			optimized32 {
				${optimized}
			}
		}
	}

}
`;
