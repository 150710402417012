import React, { useState } from "react";
import styles from "./faq.module.scss";
import openSrc from "icons/open.svg";
import SVG from "react-inlinesvg";
import useMeasure from "react-use-measure";
import { useSpring, a } from "react-spring";
import { makeId } from "helpers";

const Faq = ({ heading, faq }) => {
  return (
    <div className={styles.element}>
      <div id={makeId(heading)} className={styles.anchor}></div>
      <div className={styles.inner}>
        <h2 className={styles.heading}>{heading}</h2>

        <ul className={styles.list}>
          {faq.map((props) => (
            <Item key={props.id} {...props} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Faq;

const Item = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  const [buttonRef, buttonBounds] = useMeasure();
  const [wholeRef, wholeBounds] = useMeasure();
  const props = useSpring({
    height: open ? wholeBounds.height : buttonBounds.height,
    opacity: open ? 1 : 0,
    transform: `rotate(${open ? 45 : 0}deg)`,
  });
  return (
    <a.li
      className={styles.item}
      style={{
        height: props.height,
        overflow: "hidden",
      }}
    >
      <div ref={wholeRef}>
        <button
          ref={buttonRef}
          className={styles.question}
          onClick={() => setOpen(!open)}
        >
          {question}{" "}
          <a.div style={{ transform: props.transform }}>
            <SVG src={openSrc} />
          </a.div>
        </button>
        <a.div
          style={{ opacity: props.opacity }}
          className={styles.answer}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </a.li>
  );
};
