import React from "react";
import { Switch, Route } from "react-router-dom";
import Nav from "components/nav";
import ProductPage from "components/product-page";
import ServicePage from "components/service-page";
import ScrollMemory from "react-router-scroll-memory";
import DefaultPage from "components/default-page";
import Footer from "components/footer";
import BlogPage from "components/blog-page";

const App = () => {
  return (
    <>
      <ScrollMemory />
      <Nav />
      <Switch>
        <Route path="/products/:slug" component={ProductPage} />
        <Route path="/services/:slug" component={ServicePage} />
        <Route path="/blog/:slug" component={BlogPage} />
        <Route path="/:slug?" component={DefaultPage} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
