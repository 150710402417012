import { idtype, image32 } from "querypieces";

export default `
...on pageBuilder_services_BlockType {
	${idtype}
	services {
		...on services_service_Entry {
			id
			title
			richText
			${image32}
			icon {
				url
				...on images_Asset {
					title
					altText
				}
			}
			uri
		}
	}
	cardSize
}
`;
