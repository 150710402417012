import { idtype } from "querypieces";

const faq = `
	faq {
		...on faq_item_BlockType {
			id
			question
			answer
		}
	}
`;

export default `
...on pageBuilder_faq_BlockType {
	${idtype}
	heading
	${faq}
}
`;
