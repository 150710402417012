import React from "react";
import styles from "./wide-image.module.scss";
import { Source, Img } from "components/image";

const WideImage = ({ image }) => {
  const { alt, title, optimized41, optimized32 } = image[0];
  return (
    <div className={styles.element}>
      {/* <Image {...image[0].optimized41} /> */}
      <picture>
        <Source
          srcset={optimized41.srcset}
          srcsetWebp={optimized41.srcsetWebp}
          media={"(min-width: 768px)"}
        />
        <Source
          srcset={optimized32.srcset}
          srcsetWebp={optimized32.srcsetWebp}
        />
        <Img
          placeholderImage={optimized41.placeholderImage}
          src={optimized41.src}
          srcset={optimized41.srcset}
          alt={alt || title}
        />
      </picture>
    </div>
  );
};

export default WideImage;
