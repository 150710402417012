import React from "react";
import Page from "components/page";
import Blocks from "blocks";
import query from "./query";
import styles from "./blog-page.module.scss";
import { gql, useQuery } from "@apollo/react-hooks";
import { blogCard } from "querypieces";
import { Cards } from "blocks/blog-posts/blog-posts";

const BlogPage = ({ match }) => {
  return (
    <Page match={match} query={query}>
      {(data) => (
        <>
          <div className={styles.element}>
            <header>
              <div className={styles.date}>{data.entry.postDate}</div>
              <h1 className={styles.heading}>{data.entry.title}</h1>
            </header>
            <main>
              <Blocks blocks={data.entry.blogBuilder} />
            </main>
          </div>
          <Related notId={data.entry.id} />
        </>
      )}
    </Page>
  );
};

export default BlogPage;

const useRelated = ({ notId }) => {
  return useQuery(gql`{
  entries(section: "blog", limit: 2, id: "not ${notId}") {
    ${blogCard}
  }
}`);
};

const Related = ({ notId }) => {
  const { data, error } = useRelated({ notId });
  if (error) {
    return null;
  }
  return (
    <section className={styles.related}>
      <div className={styles.relatedInner}>
        <h2 className={styles.relatedHeading}>Related News</h2>
        <Cards
          className={styles.relatedCards}
          items={data && data.entries}
          quantity={2}
        />
      </div>
    </section>
  );
};
