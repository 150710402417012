import React from "react";
import styles from "./people.module.scss";
import Person from "./person";

const People = ({ heading, people }) => {
  return (
    <div className={styles.element}>
      <h2 className={styles.heading}>{heading}</h2>
      <ul className={styles.list}>
        {people.map((props) => (
          <Person key={props.id} {...props} />
        ))}
      </ul>
    </div>
  );
};

export default People;
