import React from "react";

//content blocks
import imageHeadingLink from "./image-heading-link";
import statement from "./statement";
import products from "./products";
import wideImage from "./wide-image";
import sect from "./sect";
import text from "./text";
import embed from "./embed";
import features from "./features";
import quote from "./quote";
import services from "./services";
import people from "./people";
import faq from "./faq";
import image from "./image";
import blogPosts from "./blog-posts";
import form from "./form";
import headingOnly from "./heading-only";
import faqSubnav from "./faq-subnav";
import code from "./code";
import bigQuote from "./big-quote";
/* import-cursor */

const components = {
  imageHeadingLink,
  statement,
  products,
  wideImage,
  sect,
  text,
  embed,
  features,
  quote,
  services,
  people,
  faq,
  image,
  blogPosts,
  form,
  headingOnly,
  faqSubnav,
  code,
  bigQuote,
  /* object-cursor */
};

const Blocks = ({ blocks, entry }) => {
  return blocks.map((block, index) => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={block.id} {...block} entry={entry} blockIndex={index} />;
  });
};
export default Blocks;
