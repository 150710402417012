import { gql } from "apollo-boost";
import { pageBuilder } from "blocks/query";
import { query as seomatic } from "components/seomatic-helmet";
export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ...on pages_page_Entry {
        typeHandle
        ${pageBuilder}
      }
    }
    ${seomatic}
  }
`;
