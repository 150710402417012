import React from "react";
import Image from "components/image";
import styles from "./product.module.scss";
import { Link as RouterLink } from "react-router-dom";
import Link from "components/link";
import RichText from "components/rich-text";

const Product = ({
  title,
  productId,
  shortDescription,
  image,
  uri,
  size,
  align,
  hasPage,
  richText,
  productCollection,
  linkTo,
}) => {
  const inner = (
    <div className={styles.inner}>
      <div className={styles.text}>
        <div className={styles.id}>{productId}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.shortDescription}>{shortDescription}</div>
        {size === "smallDetailed" && (
          <RichText className={styles.richText} text={richText} />
        )}
      </div>
      {(hasPage || linkTo.url) && (
        <div className={styles.link}>
          {linkTo.url
            ? linkTo.text
            : hasPage && `View Product${productCollection ? "s" : ""}`}
        </div>
      )}
      {image[0] && (
        <Image
          {...(size === "large"
            ? image[0].optimized32
            : size === "medium" || size === "small" || size === "smallDetailed"
            ? image[0].optimized11
            : null)}
          className={styles.image}
        />
      )}
    </div>
  );
  return (
    <div className={`${styles.element} ${size} ${align}`}>
      {linkTo.url ? (
        <Link {...linkTo}>{inner}</Link>
      ) : hasPage ? (
        <RouterLink to={`/${uri}`}>{inner}</RouterLink>
      ) : (
        inner
      )}
    </div>
  );
};

export default Product;
