import { gql } from "apollo-boost";
import { pageBuilder } from "blocks/query";
import { idtype, image41 } from "querypieces";
import { query as seomatic } from "components/seomatic-helmet";

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ...on services_service_Entry {
        ${idtype}
        title
        richText
        icon {
          url
        }
        ${image41}
        ${pageBuilder}
      }
    }
    services: entries(section: "services") {
      ...on services_service_Entry {
        ${idtype}
        title
        icon {
          url
        }
        uri
      }
    }
    ${seomatic}
  }
`;
