import React from "react";
import styles from "./features.module.scss";

const Features = ({ productFeatures }) => {
  return (
    <ul className={styles.list}>
      {productFeatures.map(({ id, heading, text }) => (
        <li key={id} className={styles.item}>
          <div className={styles.heading}>{heading}</div>
          <div className={styles.text}>{text}</div>
        </li>
      ))}
    </ul>
  );
};

export default Features;
