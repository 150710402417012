import React from "react";
import Blocks from "blocks";
import Page from "components/page";
import query from "./query";
import Image from "components/image";
import styles from "./service-page.module.scss";
import Services from "blocks/services";

const ServicePage = ({ match }) => {
  return (
    <Page match={match} query={query}>
      {({ entry, services }) => {
        return (
          <>
            <div className={styles.hero}>
              {entry.image[0] && (
                <div className={styles.image}>
                  <div className={styles.imageInner}>
                    <Image {...entry.image[0].optimized} />
                  </div>
                </div>
              )}
              <div className={styles.heroText}>
                <h1 className={styles.heading}>
                  {entry.title}{" "}
                  <img
                    className={styles.icon}
                    src={entry.icon[0].url}
                    alt={`${entry.title} icon`}
                  />
                </h1>
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{ __html: entry.richText }}
                />
              </div>
            </div>
            <Blocks blocks={entry.pageBuilder} />
            <Services
              services={services.filter(({ id }) => id !== entry.id)}
              cardSize={"small"}
            />
          </>
        );
      }}
    </Page>
  );
};

export default ServicePage;
