import React from "react";
import usePage from "./usePage";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import styles from "./page.module.scss";
import SeomaticHelmet from "components/seomatic-helmet";

const Page = ({ match, children, query }) => {
  const { loading, error, data } = usePage({
    uri: match.url.replace("/", ""),
    query,
  });

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  return (
    <main className={styles.element}>
      <SeomaticHelmet seomatic={data.seomatic} />
      {children(data)}
    </main>
  );
};

export default Page;
