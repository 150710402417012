import { gql } from "apollo-boost";
import { blogBuilder } from "blocks/query";
import { query as seomatic } from "components/seomatic-helmet";

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ...on blog_blog_Entry {
        id
        typeHandle
        title
        postDate @formatDateTime (format: "F j, Y")
        ${blogBuilder}
      }
    }
    ${seomatic}
  }
`;
