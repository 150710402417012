import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styles from "./newsletter-subscribe.module.scss";

const NewsletterSubscribe = ({ url }) => {
  const [email, setEmail] = useState("");
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              subscribe({
                EMAIL: email,
              });
            }}
            className={`${status === "error" ? styles.formError : styles.form}`}
          >
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.input}
            />
            <button type="submit" className={styles.submit}>
              Submit
            </button>
          </form>
          {status && (
            <div className={styles.status}>
              {status === "sending" && (
                <div className={styles.sending}>sending...</div>
              )}
              {status === "error" && (
                <div
                  className={styles.error}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === "success" && (
                <div className={styles.success}>Subscribed!</div>
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default NewsletterSubscribe;
