import React from "react";
import styles from "./big-quote.module.scss";

const BigQuote = ({ plainText, attribution, theme }) => {
  return (
    <div className={`${styles.element} theme--${theme}`}>
      <div className={styles.inner}>
        <div className={styles.text}>{plainText}</div>
        {attribution && <div className={styles.attribution}>{attribution}</div>}
      </div>
    </div>
  );
};

export default BigQuote;
