import lazySizes from "lazysizes";
import React from "react";
import { render, hydrate } from "react-dom";
import App from "components/app";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import createApolloClient from "./create-apollo-client";
import "./index.css";
import "./scss/main.scss";

import * as serviceWorker from "./serviceWorker";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const client = createApolloClient({ baseUrl: process.env.REACT_APP_API });

const renderMethod = !!module.hot ? render : hydrate;

lazySizes.init();

renderMethod(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
