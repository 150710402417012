import React from "react";
import Page from "components/page";
import Blocks from "blocks";
import query from "./query";

const DefaultPage = ({ match }) => {
  return (
    <Page match={match} query={query}>
      {(data) => <Blocks blocks={data.entry.pageBuilder} />}
    </Page>
  );
};

export default DefaultPage;
