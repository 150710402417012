import React from "react";
import styles from "./person.module.scss";
import Image from "components/image";
import RichText from "components/rich-text";

const Person = ({ fullName, jobTitle, bio, image }) => {
  return (
    <li className={styles.element}>
      <div className={styles.name}>{fullName}</div>
      <div className={styles.title}>{jobTitle}</div>
      <RichText className={styles.bio} text={bio} />
      <Image {...image[0].optimized} className={styles.image} />
    </li>
  );
};

export default Person;
