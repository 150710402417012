import { idtype, linkTo } from "querypieces";

export default `
...on pageBuilder_statement_BlockType {
	${idtype}
	plainText
	theme
	${linkTo}
}
`;
