import { gql } from "apollo-boost";
import { pageBuilder } from "blocks/query";
import { productImage, idtype } from "querypieces";
import { query as seomatic } from "components/seomatic-helmet";

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ...on products_product_Entry {
        typeHandle
        title
        productId
        shortDescription
        richText
        productImages {
          ...on productImages_photo_BlockType {
            ${idtype}
            ${productImage}
          }
        }
        ${pageBuilder}
      }
    }
    ${seomatic}
  }
`;
