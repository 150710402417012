import React from "react";
import styles from "./product-images.module.scss";
import Image from "components/image";
import { MagnifierPreview, Magnifier } from "react-image-magnifiers";

const ProductImages = ({
  images,
  className,
  galleryView,
  setGalleryView,
  wide,
  setWide,
  selected,
  setSelected,
  desktop,
}) => {
  return (
    <div className={`${styles.element} ${className}`}>
      <Detail
        image={images[selected].image}
        galleryView={galleryView}
        setGalleryView={setGalleryView}
        selected={selected}
        setWide={setWide}
        wide={wide}
        desktop={desktop}
      />
      {images.length > 1 && (
        <Picker
          images={images}
          selectedIndex={selected}
          setSelectedIndex={setSelected}
        />
      )}
    </div>
  );
};

export default ProductImages;

const Detail = ({ image, setGalleryView, setWide, selected, desktop }) => {
  const imageProps = image[0]["optimized11"];
  return (
    <div
      className={styles.detail}
      onMouseEnter={() => {
        setGalleryView(selected);
        setWide(true);
      }}
      onMouseLeave={() => {
        setGalleryView(null);
        setWide(false);
      }}
    >
      {desktop ? (
        <MagnifierPreview imageSrc={imageProps.src} />
      ) : (
        <Magnifier imageSrc={imageProps.src} key={imageProps.src} />
      )}

      {/* <SideBySideMagnifier imageSrc={imageProps.src} alwaysInPlace /> */}
      {/* <Image key={imageProps.src} {...imageProps} /> */}
    </div>
  );
};

const Picker = ({ images, selectedIndex, setSelectedIndex }) => {
  return (
    <div className={styles.picker}>
      {images.map(({ id, image }, i) => (
        <button
          className={
            selectedIndex === i ? styles.selectedPickerItem : styles.pickerItem
          }
          onMouseOver={() => setSelectedIndex(i)}
          key={id}
          onFocus={() => setSelectedIndex(i)}
        >
          <div className={styles.pickerItemImage}>
            <Image {...image[0].optimized11} />
          </div>
        </button>
      ))}
    </div>
  );
};
