import React from "react";
import styles from "./services.module.scss";
import { Link } from "react-router-dom";
import Image from "components/image";
import RightArrow from "components/right-arrow";

const Services = ({ services, cardSize }) => {
  return (
    <div className={`${cardSize === "large" ? "" : styles.smallCards}`}>
      {services.map((props) => {
        return cardSize === "large" ? (
          <Service key={props.id} {...props} />
        ) : (
          <Card key={props.id} {...props} />
        );
      })}
    </div>
  );
};

export default Services;

const Service = ({ title, richText, icon, uri, image }) => {
  return (
    <div className={styles.item}>
      <h2 className={styles.heading}>
        {title}{" "}
        <div className={styles.icon}>
          <img src={icon[0].url} alt={icon[0].alt || icon[0].title} />
        </div>
      </h2>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: richText }}
      />
      <Image {...image[0].optimized} className={styles.image} />
      <Link className={styles.link} to={`/${uri}`}>
        Explore
      </Link>
    </div>
  );
};

const Card = ({ title, icon, uri }) => {
  return (
    <div className={styles.card}>
      <Link className={styles.cardLink} to={`/${uri}`}>
        <h2 className={styles.cardHeading}>{title}</h2>
        <img className={styles.cardIcon} src={icon[0].url} alt="TODO" />
        <div className={styles.cardArrow}>
          <RightArrow />
        </div>
      </Link>
    </div>
  );
};
