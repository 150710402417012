import { idtype, linkTo, image32 } from "querypieces";

export default `
...on pageBuilder_imageHeadingLink_BlockType {
	${idtype}
	heading
	${linkTo}
	${image32}
}
`;
