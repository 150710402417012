import { idtype } from "querypieces";

export default `
...on pageBuilder_form_BlockType {
	${idtype}
	heading
	richText
	form
}
`;
