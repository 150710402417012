import React from "react";
import styles from "./quote.module.scss";

const Quote = ({ plainText, attribution }) => {
  return (
    <div className={styles.element}>
      <div className={styles.quote}>{plainText}</div>
      {attribution && <div className={styles.attribution}>– {attribution}</div>}
    </div>
  );
};

export default Quote;
