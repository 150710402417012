import React from "react";
import styles from "./faq-subnav.module.scss";
import Blocks from "blocks";
import { makeId } from "helpers";
const FaqSubnav = ({ heading, children }) => {
  return (
    <div className={styles.element}>
      <div className={styles.header}>
        <h2 className={styles.heading}>{heading}</h2>
      </div>
      <nav className={styles.subnav}>
        <ul className={styles.subnavList}>
          {children.map(({ id, heading }) => {
            return (
              <li key={id}>
                <a href={`#${makeId(heading)}`}>{heading}</a>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={styles.body}>
        <Blocks blocks={children} />
      </div>
    </div>
  );
};

export default FaqSubnav;
