import React from "react";
import styles from "./footer.module.scss";
import NewsletterSubscribe from "components/newsletter-subscribe";
import { useQuery } from "@apollo/react-hooks";
import query from "./query";
import ButtonLink from "components/button-link";
import nl2br from "react-nl2br";
import Link from "components/link";

const Footer = () => {
  return (
    <footer className={styles.element}>
      <Inner />
    </footer>
  );
};

export default Footer;

const Inner = () => {
  const { loading, error, data } = useQuery(query);
  if (error) {
    console.error(error);
  }
  if (loading || error) {
    return null;
  }

  const {
    contactHeading,
    mailchimpFormActionLink,
    contactLink,
    addressHeading,
    address,
    additionalLinksHeading,
    additionalLinks,
    socialLinksHeading,
    socialLinks,
    copyrightNotice,
    termsLink,
  } = data.globalSet.footer[0];

  return (
    <>
      <Section className={styles.subscribe} heading={contactHeading}>
        <NewsletterSubscribe url={mailchimpFormActionLink} />
        <ButtonLink {...contactLink} className={styles.subscribeContact} />
      </Section>
      <Section className={styles.address} heading={addressHeading}>
        <div className={styles.address}>{nl2br(address)}</div>
      </Section>
      <Section className={styles.additional} heading={additionalLinksHeading}>
        <Links links={additionalLinks} />
      </Section>
      <Section className={styles.social} heading={socialLinksHeading}>
        <Links links={socialLinks} />
      </Section>
      <div className={styles.bottom}>
        <div>
          {copyrightNotice.replace("*currentYear*", new Date().getFullYear())}
        </div>
        <Link {...termsLink} data-text={termsLink.text} />
      </div>
    </>
  );
};

const Section = ({ heading, children, className = "" }) => {
  return (
    <div className={className}>
      <div className={styles.sectionHeading}>{heading}</div>
      {children}
    </div>
  );
};

const Links = ({ links }) => {
  return (
    <ul className={styles.links}>
      {links.map(({ id, linkTo }) => (
        <li key={id}>
          <Link {...linkTo} data-text={linkTo.text} />
        </li>
      ))}
    </ul>
  );
};
