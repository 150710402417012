import { idtype } from "querypieces";
import faq from "blocks/faq/query";

export default `
...on pageBuilder_faqSubnav_BlockType {
	${idtype}
	heading
	children {
		${faq}
	}
}
`;
