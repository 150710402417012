import { idtype } from "querypieces";

export default `
...on pageBuilder_embed_BlockType {
	${idtype}
	embed {
		code
		aspectRatio
		width
		height
	}
}
`;
