import React, { useState } from "react";
import styles from "./product-detail.module.scss";
import ProductImages from "components/product-images";
//import Image from "components/image";
import { MagnifierContainer, MagnifierZoom } from "react-image-magnifiers";
import useMinWidth from "hooks/useMinWidth";

const ProductDetail = ({
  title,
  shortDescription,
  richText,
  productImages,
  productId,
}) => {
  const [galleryView, setGalleryView] = useState(null);
  const [wide, setWide] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const desktop = useMinWidth(800);

  return (
    <MagnifierContainer>
      <div className={`${styles.element} ${wide ? styles.gallery : ""}`}>
        <div className={styles.info}>
          {wide && desktop ? (
            <MagnifierZoom
              className={styles.zoom}
              style={{ height: "100%" }}
              imageSrc={productImages[selectedImage].image[0].optimized32.src}
            />
          ) : (
            <>
              <div className={styles.id}>{productId}</div>
              <h1 className={styles.heading}>{title}</h1>
              <div className={styles.subheading}>{shortDescription}</div>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: richText }}
              />
            </>
          )}
        </div>
        {productImages.length > 0 && (
          <ProductImages
            desktop={desktop}
            className={styles.images}
            images={productImages}
            galleryView={galleryView}
            setGalleryView={setGalleryView}
            setWide={setWide}
            selected={selectedImage}
            setSelected={setSelectedImage}
          />
        )}
      </div>
    </MagnifierContainer>
  );
};

export default ProductDetail;
