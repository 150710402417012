import React from "react";
import styles from "./blog-posts.module.scss";
import useBlogPosts from "./use-blog-posts.js";
import { Link } from "react-router-dom";
import Loading from "components/loading";
import RightArrow from "components/right-arrow";
import Image from "components/image";
import ButtonLink from "components/button-link";

const BlogPosts = ({
  heading,
  initialPosts,
  blockIndex,
  blogPostsButtonType,
}) => {
  const { loading, error, data, loadMore } = useBlogPosts({
    initialPosts,
  });
  const Tag = blockIndex === 0 ? "h1" : "h2";

  return (
    <section className={styles.element}>
      {heading && <Tag className={styles.heading}>{heading}</Tag>}
      {error ? (
        "something went wrong"
      ) : (
        <>
          <Cards
            items={data && data.entries}
            quantity={initialPosts}
            className={styles.cardsThree}
          />
          <Button
            type={blogPostsButtonType}
            data={data}
            loadMore={loadMore}
            loading={loading}
          />
        </>
      )}
    </section>
  );
};

export default BlogPosts;

const Button = ({ type, data, loadMore, loading }) => {
  switch (type) {
    case "linkToBlog":
      return (
        <ButtonLink
          className={styles.loadMore}
          type="entry"
          element={{ uri: "blog" }}
          text={"Go To Blog"}
        />
      );
    default:
      return (
        !data ||
        (data.entries.length < data.total &&
          (loading ? (
            <Loading />
          ) : (
            <button className={styles.loadMore} onClick={loadMore}>
              Load More
            </button>
          )))
      );
  }
};

export const Cards = ({ items, quantity, className = "" }) => {
  return (
    <ul className={`${styles.cards} ${className}`}>
      {items
        ? items.map((props) => <Card key={props.id} {...props} />)
        : new Array(quantity).fill(null).map((d, i) => <CardLoader key={i} />)}
    </ul>
  );
};

const Card = ({ title, postDate, uri, cardColor, image, ...rest }) => {
  return (
    <li className={styles.card}>
      <Link to={`/${uri}`} className={cardColor}>
        {image[0] && <Image {...image[0].optimized} />}
        <div className={styles.cardInner}>
          <div className={styles.cardDate}>{postDate}</div>
          <div className={styles.cardHeading}>{title}</div>
          <div className={styles.cardCta}>
            Read <RightArrow />
          </div>
        </div>
      </Link>
    </li>
  );
};

const CardLoader = () => {
  return <li className={styles.cardLoader}></li>;
};
