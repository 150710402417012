import React from "react";
import styles from "./statement.module.scss";
import ButtonLink from "components/button-link";

const Statement = ({ plainText, theme, linkTo }) => {
  return (
    <div className={`${styles.element} theme--${theme}`}>
      <div className={styles.inner}>
        <div className={styles.text}>{plainText}</div>
        {linkTo.url && <ButtonLink {...linkTo} className={styles.link} />}
      </div>
    </div>
  );
};

export default Statement;
