import React, { useEffect, useState } from "react";
import styles from "./nav.module.scss";
import { useQuery } from "@apollo/react-hooks";
import query from "./query";
import Link from "components/link";
import logo from "./logo.svg";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as MenuIcon } from "icons/menu.svg";
import useMinWidth from "hooks/useMinWidth";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const isDesktop = useMinWidth(1300);
  const { loading, error, data } = useQuery(query);

  useEffect(() => {
    if (isDesktop) {
      document.body.style.overflow = "";
    }
  }, [isDesktop]);

  if (loading || error) {
    return null;
  }
  return (
    <nav className={isDesktop ? styles.elementDesktop : styles.elementMobile}>
      <RouterLink to="/" className={styles.desktopLogoLink}>
        <img className={styles.logo} src={logo} alt="Ensure A Seal" />
      </RouterLink>
      {data && isDesktop ? (
        <>
          {data.nav.phoneNumber && (
            <a
              className={styles.desktopPhoneNumber}
              href={`tel:${data.nav.phoneNumber}`}
            >
              {data.nav.phoneNumber}
            </a>
          )}
          <Links data={data} className={styles.desktopLinks} />
        </>
      ) : (
        <Menu data={data} />
      )}
    </nav>
  );
};

export default Nav;

const Links = ({ data, className = "", onLinkClick }) => {
  let location = useLocation();
  return (
    <ul className={className}>
      {data.nav.builder.map((block) => {
        switch (block.typeHandle) {
          case "item":
            return (
              <li key={block.id}>
                <Link
                  {...block.linkTo}
                  onClick={onLinkClick}
                  className={
                    block.linkTo.element &&
                    `/${block.linkTo.element.uri}` === location.pathname
                      ? "active"
                      : ""
                  }
                />
              </li>
            );
          default:
            console.error(`unknown block type ${block.typeHandle}`);
            return null;
        }
      })}
    </ul>
  );
};

const Menu = ({ data }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "";
  }, [open]);

  return (
    <div className={styles.buttonSpacer}>
      <button
        className={open ? styles.menuToggleOpen : styles.menuToggleClosed}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon />
      </button>
      {open && (
        <Links
          data={data}
          className={styles.mobileLinks}
          onLinkClick={() => setOpen(false)}
        />
      )}
    </div>
  );
};
