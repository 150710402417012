import React from "react";
import styles from "./loading.module.scss";
import { ReactComponent as Logo } from "icons/mark.svg";

const Loading = () => {
  return (
    <div className={styles.element}>
      <Logo title="Loading" />
    </div>
  );
};

export default Loading;
