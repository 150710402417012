import React from "react";
import styles from "./sect.module.scss";
import Blocks from "blocks";

const Sect = ({ heading, theme, children }) => {
  return (
    <div className={`${styles.element} theme--${theme}`}>
      <h2 className={styles.heading}>{heading}</h2>
      <div className={styles.body}>
        <Blocks blocks={children} />
      </div>
    </div>
  );
};

export default Sect;
