import { idtype } from "querypieces";
import text from "blocks/text/query";
import embed from "blocks/embed/query";
import features from "blocks/features/query";
import quote from "blocks/quote/query";

export default `
...on pageBuilder_sect_BlockType {
	${idtype}
	heading
	children {
		${embed}
		${text}
		${features}
		${quote}
	}
	theme
}
`;
